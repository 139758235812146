<template>
  <modal
    :width="700"
    :adaptive="true"
    class="supportPopup CEModal modal-scroll-bar"
    name="supportPopup"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <h4 class="text-center fs18 m-b-10 m-b-5">
      {{ $t('AUTHFOOTER.SUPPORT') }}
    </h4>
    <p class="text-center">
      {{ $t("AUTHFOOTER.ANSWER_HOURS") }}: 09:00-17:00
    </p>
    <div class="d-flex support-contents m-t-20">
      <div class="support-item text-center">
        <img src="/assets/img/icons/desktop-light.svg" alt="">
        <p>{{ $t("AUTHFOOTER.SCREEN_SHARING") }}</p>
        <div class="d-flex justify-content-center m-t-10">
          <a 
            href="https://get.anydesk.com/qhIQXxXn/PelePaid_Support.pkg"
            class="text-center p5"
            target="_blank"
          > 
            <img src="/assets/img/os/apple.png" class="os-img" alt="">
            <h4>{{ $t("SUPPLIER_PAYMENT.MACINTOSH") }}</h4>
          </a>
          <a 
            href="https://get.anydesk.com/IIS8MKrJ/PelePaid_Support.tar.gz"
            class="text-center p5"
            target="_blank"
          > 
            <img src="/assets/img/os/ubuntu.png" class="os-img" alt="">
            <h4>{{ $t("MANAGEMENT.LINUX") }}</h4>
          </a>
          <a 
            href="https://get.anydesk.com/NPHIFMZd/PelePaid_Support.exe"
            class="text-center p5"
            target="_blank"
          > 
            <img src="/assets/img/os/windows.png" class="os-img" alt="">
            <h4>{{ $t("MANAGEMENT.WINDOWS") }}</h4>
          </a>
        </div>
      </div>
      <div class="support-item text-center chat-item">
        <img 
          src="/assets/img/icons/chat_icon.svg" 
          alt=""
          class="chat-icon"
        >
        <p>{{ $t("AUTHFOOTER.CHAT_INTRO_DESC") }}</p>
      </div>
      <div class="support-item text-center">
        <img 
          src="/assets/img/icons/user-headset-light-primary.svg" 
          alt=""
          class="phone-support-img"
        >
        <p>{{ $t("AUTHFOOTER.PHONE_SUPPORT") }}</p>
        <h3 class="color-blue6">5904 *</h3>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'supportPopup',
  data() {
    return {

    }
  },
  methods: {
    closeModal() {
      this.$modal.hide("supportPopup");
    },
  }
}
</script>

<style lang="scss">
.supportPopup {
  .vm--modal {
    padding: 10px;
  }
}
</style>
<style lang="scss" scoped>
.supportPopup {
  font-size: 14px;
  .support-contents {
    @media (max-width: 700px) {
      flex-wrap: wrap;
    }
    .support-item {
      width: 100%;
      padding: 10px 20px;
      .os-img {
        width: 30px;
        height: 30px;
      }
      .phone-support-img {
        height: 40px;
      }
      .chat-icon {
        height: 60px;
      }
    }
    .chat-item {
      border-left: 1px solid #dedede;
      border-right: 1px solid #dedede;
      @media (max-width: 700px) {
        border-left: none;
        border-right: none;
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
      }
    }
  }
}
</style>