<template>
  <main class="login">
    <div v-if="isConfirm">
      <forgetpassword @clicked="loginScreen" :loginName="username"></forgetpassword> 
    </div>
    <div v-else>
    <h1 class="title login__title">Log-in</h1>
    <div class="login__enter">
      <h3 class="title title__h3 text-center w-100">{{ $t("LOGIN.ENTRANCE") }}</h3>
      <p class="text login__text text-center w-100">{{ $t("LOGIN.LOGIN_TEXT") }}</p>
      <form @submit.prevent="handleSubmit" class="authorisation login__authorisation">
        <div class="authorisation__name">
          <label class="label authorisation__label" for="username">{{ $t("LOGIN.USERNAME") }}</label>
          <input
            id="username"
            v-model="username"
            v-model.lazy="$v.username.$model"
            class="input authorisation__input"
            :class="{ 'validation-error': $v.username.$error }"
            type="text"
            @keyup.enter="onEnter('uname')"
          />
        </div>
        <div class="authorisation__password">
          <label class="label authorisation__label" for="password">{{ $t("LOGIN.PASSWORD") }}</label>
          <input
            id="password"
            v-model="password"
            v-model.lazy="$v.password.$model"
            class="input authorisation__input"
            :class="{ 'validation-error': $v.password.$error }"
            type="password"
            @keyup.enter="onEnter('upass')"
          />
        </div>
        <div class="authorisation__remember">
          <!-- <div>
            <input class="input authorisation__checkbox" id="remember" type="checkbox" />
            <label
              class="label authorisation__label authorisation__remember-me"
              for="remember"
            >{{ $t("LOGIN.REMEMBER_ME") }}</label>
          </div> -->
          <a
            class="link authorisation__forgot"
            @click="changeConfirm">
            {{ $t("LOGIN.FORGOT_PASSWORD") }}</a>
        </div>
        <a
          class="link authorisation__button color-white"
          @click="handleSubmit"
        >{{ $t("LOGIN.LOGIN") }}</a>
      </form>
    </div>
    <div class="login-contact-block df-c m-t-30 fs16 weight-700" @click="openSupportModal">
      <div class="contact-btn df-c mx10">
        <img src="/assets/img/user-headset-light.svg" alt="">
      </div>
      {{ $t("LOGIN.CONTACT_US") }}
    </div>
    <loading :active.sync="loggingIn" :can-cancel="false" :is-full-page="true"></loading>
    </div>
    <SupportPopup></SupportPopup>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import forgetpassword from "./ForgetPassword";
import SupportPopup from "@/components/SupportPopup.vue";

export default {
  
  mixins: [validationMixin],
  components: {
    Loading,
    forgetpassword,
    SupportPopup
  },
  name: "Login",
  computed: {
    ...mapState({
      loggingIn: state => state.account.loggingIn,
      loggedIn: state => state.account.loggedIn
    })
  },
  data: () => {
    return {
      username: "",
      password: "",
      isConfirm: false,
    };
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    ...mapActions("permission", {
      getUserPermissionsData: "getUserPermissionsData"
    }),
    async handleSubmit() {
      let obj = this;
      obj.$v.$touch();
      if (obj.$v.$invalid) {
        return;
      }
      const { username, password } = obj;
      if (username && password) {
        this.login({ username, password }).then(
          async result => {
            if (obj.loggedIn) {
              try {
                await obj.$router.push(obj.$route.query.redirect || "/");
              } catch (e) {}
            }
            return result;
          },
          error => {
            obj.password = "";
            return error;
          }
        );
      }
    },
    changeConfirm() {
      this.isConfirm= true;
    },
    loginScreen(change) {
      this.isConfirm= change;
    },
    onEnter(formType) {
      if (formType == 'uname' && this.password == "") {
        document.getElementById("password").focus();
      } else if (formType == 'upass' && this.username == "") {
        document.getElementById("username").focus();
      }

      if (this.username != "" && this.password != "") {
        this.handleSubmit();
      }
    },
    openSupportModal() {
      this.$modal.show('supportPopup');
    }
  }
};
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
}
.login__enter {
  text-align: unset;
}

.authorisation {
  text-align: unset;
}
.login-contact-block {
  .contact-btn {
    background-color: #002036;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    img {
      width: 32px;
    }
  }
}
</style>